"use strict";

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this;
    do {
      if (Element.prototype.matches.call(el, s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}
if (!Array.prototype.forEach) {
  Array.prototype.forEach = function (fun /*, thisp*/) {
    var len = this.length;
    if (typeof fun != "function") throw new TypeError();
    var thisp = arguments[1];
    for (var i = 0; i < len; i++) {
      if (i in this) fun.call(thisp, this[i], i, this);
    }
  };
}
function removeElement(element) {
  if (element.length === undefined) {
    if (element && element.parentNode) {
      element.parentNode.removeChild(element);
    }
  } else {
    for (var i = 0; i < element.length; i++) {
      if (element[i].parentNode) {
        element[i].parentNode.removeChild(element[i]);
      }
    }
  }
}
function changeObjAttrybute(elem, attrName, attrVal) {
  if (elem) {
    elem.setAttribute(attrName, attrVal);
  }
}
function changeObjText(elem, elemVal) {
  if (elem) {
    elem.innerHTML = elemVal;
  }
}
function getTranslationsFromLang() {
  var currLang = document.querySelector('body').getAttribute('data-current-lang');
  var ajaxParams = [];
  for (var i = 0; i < arguments.length; i++) {
    ajaxParams.push([arguments[i]]);
  }
  var formData = 'params=[' + ajaxParams.map(function (key) {
    return "[\"".concat(key, "\", \"").concat(currLang, "\"]");
  }).join(',').replace(/%20/g, '+') + ']';
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/langTranslation', false);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send(formData);
  if (xhr.status === 200) {
    return JSON.parse(xhr.responseText);
  } else {
    var _xhr = new XMLHttpRequest();
    _xhr.open('POST', '//' + window.location.href + "main.js:getTranslationsFromLang()\n\n Languages access has failed");
    _xhr.send(null);
  }
}
var translations = getTranslationsFromLang('form field warning', 'form date callendar button prev', 'form date callendar button next', 'form label terms tourism', 'form label terms business', 'I will upload photos later', 'I will upload documents later', 'Menu 3 Link 1');
function showPageEmail() {
  var footerMail = document.querySelector('.footer-mail');
  changeObjAttrybute(footerMail, 'href', 'mailto:' + translations['Menu 3 Link 1']);
  changeObjText(footerMail, translations['Menu 3 Link 1']);
}
function send_to_logger(data) {
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/logger.php', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("data=".concat(data));
}
function getPhoneNumbers() {
  var formData = new FormData();
  formData.append('language', document.body.dataset.currentLang);
  var xhr = new XMLHttpRequest();
  xhr.open('POST', '//' + window.location.hostname + '/get-phone-number', true);
  xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
  xhr.send("language=".concat(document.body.dataset.currentLang));
  xhr.onreadystatechange = function (e) {
    if (this.readyState === 4) {
      if (this.status === 200) {
        var data = JSON.parse(this.responseText);
        if (data.status) {
          showPagePhone(data.numbers);
        } else {
          send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers()\n\n Phone numbers access has been failed");
        }
      } else {
        send_to_logger("JSERR " + window.location.href + "main.js:getPhoneNumbers()\n\n Phone numbers access has been failed");
      }
    }
  };
}
function showPagePhone(phoneNumbers) {
  if (typeof phoneNumbers[0] !== "undefined") {
    var primaryPhone = phoneNumbers[0],
      headerPhoneAdditional = document.querySelectorAll('.navbar-phone-link-additional'),
      callusIcon = document.querySelector('.callus-icon');
    addPhoneToHeaderElements(document.querySelectorAll('.navbar-phone-link-primary'), primaryPhone);
    addPhoneToCallusElements(document.querySelectorAll('.callus-primary'), primaryPhone);
    if (typeof phoneNumbers[1] !== "undefined") {
      var additionalPhone = phoneNumbers[1];
      addPhoneToHeaderElements(headerPhoneAdditional, additionalPhone);
      addPhoneToCallusElements(document.querySelector('.callus-additional'), additionalPhone);
      removeElement(document.querySelector('.callus-single'));
      document.querySelector('.callus-dropdown').classList.remove('hidden');
    } else {
      if (callusIcon) {
        callusIcon.setAttribute('href', 'tel:' + primaryPhone.raw_number);
      }
      removeElement('.callus-dropdown');
      removeElement(headerPhoneAdditional);
    }
  }
}
function addPhoneToHeaderElements(header, data) {
  for (var i = 0; i < header.length; i++) {
    if (header[i].querySelector(".area-code")) {
      header[i].querySelector(".area-code").innerText = data.number.trim().substr(0, 3);
    }
    if (header[i].querySelector(".number")) {
      header[i].querySelector(".number").innerText = data.number.trim().substr(3);
    }
    if (header[i].querySelector(".language")) {
      header[i].querySelector(".language").innerText = '(' + data.title + ')';
    }
  }
}
function addPhoneToCallusElements(callus, data) {
  for (var i = 0; i < callus.length; i++) {
    callus[i].setAttribute('href', 'tel:' + data.raw_number);
  }
}
try {
  localStorage.setItem('localStorage', 1);
  localStorage.removeItem('localStorage');
} catch (e) {
  Storage.prototype._setItem = Storage.prototype.setItem;
  Storage.prototype.setItem = function () {};
  isStorageUsing = false;
}
function dropdownMenus() {
  var languagesMenu = document.querySelector('.languages .list-container'),
    phonesMenu = document.querySelector('#dropdown-phone-list');
  if (languagesMenu) {
    document.querySelector('.languages .current .wrapper').addEventListener('click', function () {
      languagesMenu.classList.toggle('hidden');
    });
  }
  if (phonesMenu) {
    document.querySelector('#dropdown-phone-button').addEventListener('click', function () {
      phonesMenu.classList.toggle('hidden');
    });
  }
  document.addEventListener('click', function (event) {
    if (event.target.closest('.languages .current .wrapper') === null && languagesMenu) {
      languagesMenu.classList.add('hidden');
    }
    if (event.target.closest('#dropdown-phone-button') === null && phonesMenu) {
      phonesMenu.classList.add('hidden');
    }
  });
}
dropdownMenus();
showPageEmail();
getPhoneNumbers();